<template>
	<svg viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.8033 14C14.8522 12.935 15.5665 11.5781 15.8559 10.1009C16.1453 8.62366 15.9967 7.09249 15.4291 5.70099C14.8614 4.3095 13.9001 3.12016 12.6668 2.28339C11.4334 1.44662 9.98336 1 8.5 1C7.01664 1 5.5666 1.44662 4.33324 2.28339C3.09987 3.12016 2.13858 4.3095 1.57092 5.70099C1.00326 7.09249 0.854725 8.62366 1.14411 10.1009C1.43349 11.5781 2.14778 12.935 3.19667 14M8.5 10.1009V3.96141"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

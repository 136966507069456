<template>
	<div :dusk="'move-notification-'+record.id" class="block hover:bg-jmi-purple5 cursor-pointer" @click="open(record)">
		<div :class="['flex items-center h-full', {'p-3 sm:px-6': isOverview, 'p-4 sm:px-6': isDefault || isDashboard}]">
			<div class="min-w-0 flex-1 flex items-center">
				<div class="flex-shrink-0">
					<div class="rounded-full overflow-hidden object-contain bg-jmi-coral5 p-1.5">
						<img :class="[{'h-9 w-9': isOverview || isDashboard, 'h-11 w-11': isDefault}]" :src="icon" alt="{{ record.type }}">
					</div>
				</div>
				<div :class="['min-w-0 flex-1 px-4 md:grid md:grid-cols-4', {'md:gap-4': isOverview || isDefault, 'md:gap-2': isDashboard}]">
					<div :class="['mb-2 md:mb-0', {'flex items-center': isOverview, 'col-span-2': isDefault, 'col-span-1': isOverview, 'col-span-4': isDashboard}]">
						<p class="text-sm font-medium text-jmi-purple1">
							{{ record.type }}
						</p>
						<p v-if="isDefault || isDashboard" :class="['text-sm text-jmi-purple2', {'mt-2': isDefault}]" v-html="(record._formatted ? record._formatted.address : record.address) + ' (Move ' + record.direction + ')'" />
					</div>
					<div v-if="!isDashboard" :class="{'col-span-2': isDefault, 'col-span-3': isOverview}">
						<p class="flex items-center text-sm">
							<span v-if="record.status === 'Sent'" class="flex w-full text-jmi-green2">
								<CheckCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
								<div class="truncate">
									<template v-if="record.supplier">
										{{ record.status }} to {{ record.supplier.name }}
									</template>
									<template v-else>
										Sent
									</template>
								</div>
							</span>
							<span v-else-if="record.status === 'Sendable'" class="flex text-jmi-yellow2">
								<EnvelopeIcon class="flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
								{{ record.status }}
							</span>
							<span v-else-if="record.status === 'On Hold'" class="flex text-jmi-coral1">
								<ExclamationTriangleIcon class="flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
								On hold
							</span>
							<span v-else-if="record.status === 'Unable to Send'" class="flex text-jmi-purple1">
								<NoSymbolIcon class="flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
								Unable to send
							</span>
							<span v-else class="flex text-jmi-coral1">
								<ExclamationTriangleIcon class="flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
								Missing {{ humanizedErrors }}
							</span>
						</p>
						<p v-if="record.status === 'Sent'" :class="['text-sm flex items-center text-jmi-purple2', {'mt-1': isOverview, 'mt-2': isDefault}]">
							<EnvelopeIconOutline class="flex-shrink-0 mr-1.5 h-5 w-5 stroke-1" aria-hidden="true" />
							{{ record.human_scheduled }}
						</p>
						<div v-else-if="record.status === 'Unable to Send'">
							<p :class="['text-sm flex items-center text-jmi-purple1', {'mt-1': isOverview, 'mt-2': isDefault}]">
								{{ record.supplier?.name ?? "Supplier" }} does not accept third party notifications
							</p>
						</div>
						<p v-else :class="['text-sm flex items-center text-jmi-purple2', {'mt-1': isOverview, 'mt-2': isDefault}]">
							<ClockIcon class="flex-shrink-0 mr-1.5 h-5 w-5 stroke-1" aria-hidden="true" />
							<template v-if="record.scheduled">
								Scheduled for {{ record.human_scheduled }} after 5pm
							</template>
							<template v-else>
								Not scheduled
							</template>
						</p>
						<div v-if="showAddMeterReading" class="pr-2 mt-1">
							<div v-if="showSubmitReadings || showChangeSubmittedReadings" :class="['text-sm flex items-centergap-2', {'text-jmi-purple2': showChangeSubmittedReadings, 'text-jmi-coral1': !showChangeSubmittedReadings}]">
								<IconsMeterReading class="h-5 w-5 mr-1.5 stroke-1 p-0.5 cursor-pointer" aria-hidden="true" :dusk="'meter-readings'" @click.stop="openMeterModal" />
								<p class="underline" @click.stop="openMeterModal">
									<template v-if="showChangeSubmittedReadings">
										Change readings
									</template>
									<template v-else>
										Submit readings now
									</template>
								</p>
							</div>
							<div v-else-if="showSubmittedReadings" class="text-sm flex items-center text-jmi-purple2 gap-2">
								<IconsMeterReading class="h-5 w-5 stroke-1 p-0.5 cursor-pointer" :class="'text-jmi-purple2'" aria-hidden="true" :dusk="'meter-readings'" @click.stop="openMeterModal" />
								<p class="underline text-jmi-purple2" @click.stop="openMeterModal">
									View submitted readings
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="isDashboard" class="pr-4">
				<Tooltip class="inline-block ml-1.5" popover-custom-class="transform -translate-y-full">
					<ExclamationTriangleIcon class="inline-block text-jmi-coral1 h-5 w-5 mt-[3px]" aria-hidden="true" />
					<template #tooltip>
						Missing {{ humanizedErrors }}
						<template v-if="record.scheduled">
							<br>
							Scheduled for {{ shortScheduled }}
						</template>
					</template>
				</Tooltip>
			</div>
			<div v-if="record.type">
				<ChevronRightIcon class="h-5 w-5 stroke-1 text-jmi-purple" aria-hidden="true" />
			</div>
		</div>
		<ModalsCoo :coo="openRecord" @close="close" />
		<ModalsMeterReadings v-if="showAddMeterReading" :coo="record" :is-open="openMeterReading" @close="closeMeterModal">
			<template v-if="!isOverview" #links>
				<NuxtLink class="text-jmi-purple3 text-sm underline" dusk="view-move" :to="'/moves/view/' + record.moveid">
					View move
				</NuxtLink>
			</template>
		</ModalsMeterReadings>
	</div>
</template>
<script setup lang="ts">
import {
	CheckCircleIcon,
	ExclamationTriangleIcon,
	EnvelopeIcon,
	NoSymbolIcon,
} from '@heroicons/vue/24/solid';
import {
	ChevronRightIcon,
	ClockIcon,
	EnvelopeIcon as EnvelopeIconOutline
} from '@heroicons/vue/24/outline';
import {computed, ref} from "vue";
import type {CooRecord} from "~/utils/types";
import {format} from "date-fns";

const props = withDefaults(defineProps<{
	layout?: "default" | "overview" | "dashboard";
	record: CooRecord;
}>(), {
	layout: "default",
});

const openRecord = ref(null as CooRecord | null);
const open = (record: CooRecord) => {
	openRecord.value = record;
};

const close = () => {
	openRecord.value = null;
};

const humanizedErrors = computed(() => {
	return props.record.errors.map((error) => {
		switch (error) {
			case "movedate":
				return "confirmed move date";
			case "supplierid":
				return "supplier";
			case "meter_details":
				return "gas supply information";
			default:
				return "information";
		}
	}).join(" and ");
});

const shortScheduled = computed(() => {
	return format(new Date(props.record.scheduled * 1000), 'dd/MM/yyyy');
});

const showAddMeterReading = computed(() => {
	switch (props.record.type) {
		case 'Gas':
		case 'Electricity':
		case 'Water':
			return showSubmitReadings.value || showChangeSubmittedReadings.value || showSubmittedReadings.value;
		default:
			return false;
	}
});

const isDefault = computed(() => {
	return props.layout === "default";
});

const isOverview = computed(() => {
	return props.layout === "overview";
});

const isDashboard = computed(() => {
	return props.layout === "dashboard";
});

const icon = computed(() => {
	switch (props.record.type) {
		case 'Council':
			return '/images/cooicons/council.svg';
		case 'Gas':
			return '/images/cooicons/gas.svg';
		case 'Electricity':
			return '/images/cooicons/electricity.svg';
		case 'Water':
			return '/images/cooicons/water.svg';
		case 'Sewerage':
			return '/images/cooicons/sewerage.svg';
		case 'Heating & Hot Water':
			return '/images/cooicons/heating_and_hot_water.svg';
		default:
			throw new Error("Unknown COO type: " + props.record.type);
	}
});

const openMeterReading = ref(false);

// Normal function
const openMeterModal = () => {
	openMeterReading.value = true;
};

const closeMeterModal = () => {
	openMeterReading.value = false;
};

const showSubmitReadings = computed(() => {
	return props.record.can_submit_readings;
});

const showChangeSubmittedReadings = computed(() => {
	return props.record.has_any_partner_meter_readings && props.record.can_submit_readings;
});

const showSubmittedReadings = computed(() => {
	return !props.record.can_submit_readings && props.record.has_any_partner_meter_readings;
});

</script>

<template>
	<ModalsPlain v-if="!coo.can_submit_readings" dusk="meter-reading-coo-too--old" :open="isOpen" @close="$emit('close')">
		<div class="px-4 pt-5 pb-4 sm:p-6">
			<div>
				<div :class="['mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-jmi-purple4']">
					<InformationCircleIcon class="h-6 w-6 text-jmi-purple1 stroke-1" aria-hidden="true" />
				</div>
				<div class="mt-3 text-center sm:mt-5">
					<DialogTitle as="h3" class="text-lg leading-6 font-medium text-jmi-purple1">
						{{ coo.type }} Meter Readings
					</DialogTitle>
					<div class="mt-4 text-sm text-jmi-purple2">
						<template v-if="coo.has_any_partner_meter_readings">
							<div v-for="(item, index) in meterReadings" :key="index" class="text-left flex flex-col">
								<hr v-if="index > 0" class="bg-jmi-purple5 mb-4 mt-4">
								<h3 class="text-lg leading-6 font-medium text-jmi-purple1 mb-4">
									Meter {{ index + 1 }}
								</h3>
								<dl class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
									<div v-if="isCooAdditionalInformation('Electricity', coo, item)" class="sm:col-span-1">
										<dt class="text-sm font-medium text-jmi-purple1">
											MPAN
										</dt>
										<dd class="mt-1 text-sm text-jmi-purple2 flex items-center">
											{{ item.mpan ? item.mpan : 'Not supplied' }}
										</dd>
									</div>
									<div v-if="isCooAdditionalInformation('Gas', coo, item)" class="sm:col-span-1">
										<dt class="text-sm font-medium text-jmi-purple1">
											MPRN
										</dt>
										<dd class="mt-1 text-sm text-jmi-purple2 flex items-center">
											{{ item.mprn ? item.mprn : 'Not supplied' }}
										</dd>
									</div>
									<div class="sm:col-span-1">
										<dt class="text-sm font-medium text-jmi-purple1">
											Serial number
										</dt>
										<dd class="mt-1 text-sm text-jmi-purple2 flex items-center">
											{{ item.sn ? item.sn : 'Not supplied' }}
										</dd>
									</div>
								</dl>
								<dl v-if="isCooAdditionalInformation('Electricity', coo, item)" class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 mt-3">
									<div class="sm:col-span-1">
										<dt class="text-sm font-medium text-jmi-purple1">
											Profile class
										</dt>
										<dd class="mt-1 text-sm text-jmi-purple2 flex items-center">
											{{ getProfileClass(item.profile_class) }}
										</dd>
									</div>
								</dl>
								<dl class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 mt-3">
									<div class="sm:col-span-1">
										<dt class="text-sm font-medium text-jmi-purple1">
											{{ meterReadingInfo(item)['label'] }}
										</dt>
										<dd class="mt-1 text-sm text-jmi-purple2 flex items-center">
											{{ meterReadingInfo(item)['reading'] }}
										</dd>
									</div>
									<div class="sm:col-span-1">
										<dt class="text-sm font-medium text-jmi-purple1">
											Meter reading date
										</dt>
										<dd class="mt-1 text-sm text-jmi-purple2 flex items-center">
											{{ meterReadingInfo(item)['date'] }}
										</dd>
									</div>
								</dl>
							</div>
						</template>
						<template v-else>
							We no longer accept meter readings after 4 weeks from move date.<br>We can’t guarantee suppliers will update them after this time.
						</template>
					</div>
				</div>
			</div>
		</div>
	</ModalsPlain>
	<ModalsForm
			v-else
			dusk="meter-reading-coo"
			:open="isOpen"
			:title="coo.type + ' Meter Readings'"
			:is-submitting="isSubmitting"
			label-cancel="Close"
			@close="$emit('close')"
			@submit="onSubmit"
	>
		<Alert
				v-if="alertMessage"
				class="pb-5"
				:message="alertMessage"
				type="error"
		/>

		<Alert
			v-if="doesNotHandleCoosMessage"
			class="pb-5"
			:message="doesNotHandleCoosMessage"
			type="info"
		/>

		<div v-for="(item, index) in meterReadings" :key="index">
			<hr v-if="index > 0" class="bg-jmi-purple5 mb-2">
			<div class="flex gap-4">
				<FormsInput
						v-if="isCooAdditionalInformation('Electricity', coo, item)"
						v-model="item.mpan"
						class="w-full"
						label="MPAN"
						:required="false"
						type="text"
						:name="'mpan-' + index"
				/>
				<FormsInput
						v-if="isCooAdditionalInformation('Gas', coo, item)"
						v-model="item.mprn"
						class="w-full"
						label="MPRN"
						:required="false"
						type="text"
						:name="'mprn-' + index"
				/>
				<FormsInput
						v-model="item.sn"
						class="w-full"
						label="Serial number"
						:required="false"
						:pattern="meterSerialNumberRegex"
						placeholder="A-Z, 0-9, and spaces only"
						type="text"
						:name="'s/n-' + index"
				/>
			</div>
			<FormsSelect
					v-if="isCooAdditionalInformation('Electricity', coo, item)"
					v-model="item.profile_class"
					class="mt-4 w-full"
					label="Profile class"
					:required="false"
					:options="profileClasses"
					:name="'profile_class-' + index"
			/>
			<div class="flex gap-4">
				<FormsInput
						v-model="item.partner_meter_read"
						label="Meter reading"
						class="mt-4 w-full"
						type="text"
						:name="'meter_reading-' + index"
						:required="!!item.partner_meter_read_date"
				/>
				<FormsInput
						v-model="item.partner_meter_read_date"
						:required="!!item.partner_meter_read"
						label="Meter reading date"
						type="date"
						class="mt-4 w-full"
						:name="'meter_reading_date-' + index"
						:min="maxMeterReadingDate"
						:max="todayDate"
				/>
			</div>
			<div v-if="meterReadings.length > 1" class="flex justify-start mt-3 mb-3">
				<FormsButton color="coralOutline" :dusk="'remove-meter-' + index" size="tiny" @click="meterReadings.splice(index, 1)">
					Remove meter
				</FormsButton>
			</div>
		</div>
		<div class="flex justify-end">
			<FormsButton color="lightPurple" dusk="add-meter" size="tiny" class="mt-4" @click="addNewReading()">
				Add additional meter
			</FormsButton>
		</div>
		<template #links>
			<slot name="links" />
		</template>
	</ModalsForm>
</template>

<script setup lang="ts">
import {DialogTitle} from '@headlessui/vue';
import type {CooRecord, CooAdditionalInformationInputs, VoidMeterData, CooType} from "~/utils/types";
import {ref, watch, computed, onBeforeMount} from "vue";
import {useCoos} from "~/stores/coos";
import {todayDate, maxMeterReadingDate} from "~/utils/getMinMaxAllowedMoveDates";
import {isCooAdditionalInformation, isVoidMeterData, ProfileClass, ProfileClassNames} from "~/utils/types";
import {format} from "date-fns-tz";
import {InformationCircleIcon} from "@heroicons/vue/24/solid";
import getErrorDetails from "~/utils/getErrorDetails";
import {regexString as meterSerialNumberRegex} from "~/utils/meterSerialNumberRegex";

const store = useCoos();

const emit = defineEmits(['close']);

type Props<K extends CooType = CooType> = {
	isOpen: boolean;
	coo: CooRecord<K>;
	extraMeters?: Array<VoidMeterData<K>>;
};

const props = defineProps<Props>();

let isSubmitting = ref(false);
let meterReadings = ref<CooAdditionalInformationInputs<typeof props.coo.type>[]>([]);
const alertMessage = ref<string | undefined>(undefined);

const getBlankReading = (): CooAdditionalInformationInputs<"Gas"> | CooAdditionalInformationInputs<"Electricity"> | CooAdditionalInformationInputs => {
	if (props.coo.type === "Gas") {
		return {
			id: null,
			cooid: props.coo.id,
			mprn: null,
			sn: null,
			partner_meter_read: null,
			partner_meter_read_date: null,
		};
	} else if (props.coo.type === "Electricity") {
		return {
			id: null,
			cooid: props.coo.id,
			mpan: null,
			profile_class: null,
			sn: null,
			partner_meter_read: null,
			partner_meter_read_date: null,
		};
	} else {
		return {
			id: null,
			cooid: props.coo.id,
			sn: null,
			partner_meter_read: null,
			partner_meter_read_date: null,
		};
	}
}

const addNewReading = () => {
	meterReadings.value.push(getBlankReading());
};

const onSubmit = async () => {
	isSubmitting.value = true;
	try {
		await store.saveMeterReading(meterReadings.value, props.coo.id);
		emit('close');
	} catch (e) {
		const {message, throwError} = getErrorDetails(e);
		alertMessage.value = message;
		if (throwError) {
			throw e;
		}
	} finally {
		isSubmitting.value = false;
	}
};

const profileClasses = computed(() => {
	const buffer = [];

	for (const [key, value] of Object.entries(ProfileClass)) {
		buffer.push({
			id: value,
			label: ProfileClassNames[key],
		});
	}

	return buffer;
});

const doesNotHandleCoosMessage = computed(() => {
	if (props.coo.supplier) {
		// This `?? true` is here until meilisearch is updated with the new data. If you're reading this, it's safe to remove.
		const accepts_coos = props.coo.supplier.accepts_coos ?? true;

		if (!accepts_coos) {
			return `These readings will only be sent to Rebel for the void period. As the incumbent supplier is ${props.coo.supplier.name}, we are not able to send them meter readings.`;
		}
	}

	return null;
});

const refreshReadings = (coo: CooRecord<typeof props.coo.type>) => {
	const known_mpans = [];
	const known_mprns = [];
	const known_sns = [];

	meterReadings.value = (coo.additional_information ?? [getBlankReading()]).map((reading): CooAdditionalInformationInputs<typeof props.coo.type> => {
		if (isCooAdditionalInformation("Electricity", coo, reading)) {
			known_mpans.push(reading.mpan);
			known_sns.push(reading.sn);
		} else if (isCooAdditionalInformation("Gas", coo, reading)) {
			known_mprns.push(reading.mprn);
			known_sns.push(reading.sn);
		} else {
			known_sns.push(reading.sn);
		}

		return {
			...reading,
			partner_meter_read_date: reading.partner_meter_read_date ? format(new Date(reading.partner_meter_read_date * 1000), 'yyyy-MM-dd', {timeZone: 'UTC'}) : null,
			customer_meter_read_date: reading.customer_meter_read_date ? format(new Date(reading.customer_meter_read_date * 1000), 'yyyy-MM-dd', {timeZone: 'UTC'}) : null,
		};
	});

	if (props.extraMeters) {
		for (const meter of props.extraMeters) {
			if (isVoidMeterData("Gas", meter) && known_mprns.indexOf(meter.mprn) === -1 && known_sns.indexOf(meter.sn) === -1) {
				const meterReading: CooAdditionalInformationInputs<"Gas"> = {
					id: null,
					partner_meter_read_date: null,
					partner_meter_read: null,
					cooid: coo.id,
					sn: meter.sn,
					mprn: meter.mprn,
				};
				meterReadings.value.push(meterReading);
			} else if (isVoidMeterData("Electricity", meter) && known_mpans.indexOf(meter.mpan) === -1 && known_sns.indexOf(meter.sn) === -1) {
				const meterReading: CooAdditionalInformationInputs<"Electricity"> = {
					id: null,
					partner_meter_read_date: null,
					partner_meter_read: null,
					cooid: coo.id,
					sn: meter.sn,
					mpan: meter.mpan,
					profile_class: meter.profile_class,
				};
				meterReadings.value.push(meterReading);
			}
		}
	}
};

onBeforeMount(() => {
	refreshReadings(props.coo);
});

watch(() => props.coo, (coo) => {
	refreshReadings(coo);
});

const getProfileClass = (value) => {
	let profileClass = profileClasses.value.find((item) => item.id === value);
	return profileClass ? profileClass.label : '';
};

const meterReadingInfo = (reading: CooAdditionalInformationInputs) => {
	if (reading.partner_meter_read && reading.partner_meter_read_date) {
		return {
			'label': 'Meter reading',
			'reading': reading.partner_meter_read,
			'date': reading.partner_meter_read_date ? format(new Date(reading.partner_meter_read_date), 'dd-MM-yyyy', {timeZone: 'UTC'}) : null,
		};
	} else if (reading.customer_meter_read && reading.customer_meter_read_date) {
		return {
			'label': 'Meter reading',
			'reading': 'Customer reading',
			'date': reading.customer_meter_read_date ? format(new Date(reading.customer_meter_read_date), 'dd-MM-yyyy', {timeZone: 'UTC'}) : null,
		};
	} else {
		return {
			'label': 'Meter reading',
			'reading': 'Not supplied',
			'date': 'Not supplied',
		};
	}
};

</script>

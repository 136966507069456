<template>
	<ModalsPlain v-if="coo && status !== 'Missing Information'" dusk="modals-coo" :open="is_open" @close="$emit('close')">
		<div class="px-4 pt-5 pb-4 sm:p-6">
			<div>
				<div
					:class="['mx-auto flex items-center justify-center h-12 w-12 rounded-full', {
								'bg-jmi-yellow2': status === 'Sendable',
								'bg-jmi-green2': status === 'Sent',
								'bg-jmi-coral2': status === 'On Hold',
								'bg-jmi-purple4': status === 'Unable to Send',
							}]"
>
					<template v-if="status === 'Sendable'">
						<EnvelopeIcon class="h-6 w-6 text-white/90 stroke-1" aria-hidden="true" />
					</template>
					<template v-else-if="status === 'Sent'">
						<CheckIcon class="h-6 w-6 text-white/90 stroke-1" aria-hidden="true" />
					</template>
					<template v-else-if="status === 'On Hold'">
						<BellSnoozeIcon class="h-6 w-6 text-white/90 stroke-1" aria-hidden="true" />
					</template>
					<template v-else-if="status === 'Unable to Send'">
						<NoSymbolIcon class="h-6 w-6 text-jmi-purple1 stroke-1" aria-hidden="true" />
					</template>
				</div>
				<div class="mt-3 text-center sm:mt-5">
					<DialogTitle as="h3" class="text-lg leading-6 font-medium text-jmi-purple1">
						{{ status }}
					</DialogTitle>

					<div class="mt-4 text-sm text-jmi-purple2">
						<template v-if="status === 'Sendable'">
							This <span class="text-jmi-purple1 font-medium">{{ coo.type }}</span> <span class="text-jmi-purple1 font-medium">move {{ coo.direction }}</span> notification for <span class="text-jmi-purple1 font-medium">{{ coo.address }}</span> is
							<template v-if="coo.human_scheduled">
								ready to send
								<template v-if="coo.supplier">
									to <span class="text-jmi-purple1 font-medium">{{ coo.supplier.name }}</span>
								</template>
								and is scheduled for <span class="text-jmi-purple1 font-medium">{{ coo.human_scheduled }}</span> after 5pm.
							</template>
							<template v-else>
								ready to send.
							</template>
						</template>
						<template v-else-if="status === 'Sent'">
							This <span class="text-jmi-purple1 font-medium">{{ coo.type }}</span> <span class="text-jmi-purple1 font-medium">move {{ coo.direction }}</span> notification for <span class="text-jmi-purple1 font-medium">{{ coo.address }}</span> was successfully sent
							<template v-if="coo.supplier">
								to <span class="text-jmi-purple1 font-medium">{{ coo.supplier.name }}</span>
							</template>
							on <span class="text-jmi-purple1 font-medium">{{ coo.human_scheduled }}</span>.
						</template>
						<template v-else-if="status === 'On Hold'">
							This <span class="text-jmi-purple1 font-medium">{{ coo.type }}</span> <span class="text-jmi-purple1 font-medium">move {{ coo.direction }}</span> notification for <span class="text-jmi-purple1 font-medium">{{ coo.address }}</span> is on hold.
						</template>
						<template v-else-if="status === 'Unable to Send' && coo.supplier">
							<SupplierDoesNotAcceptCoos :supplier="coo.supplier" />
						</template>
					</div>
				</div>
			</div>

			<div v-if="$route.name != 'moves-view-id'" class="mt-6 text-center">
				<FormsButton color="lightPurple" :href="'/moves/view/'+coo.moveid" dusk="view-move-button">
					View move
				</FormsButton>
			</div>
		</div>
	</ModalsPlain>
	<ModalsForm
		v-else-if="coo"
		:open="is_open"
		title="Missing Information"
		:is-submitting="isSubmitting"
		@submit="onSubmit"
		@close="$emit('close')"
	>
		<div class="flex items-center mb-6 text-sm">
			<div class="mr-4">
				<div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-jmi-coral1">
					<ExclamationTriangleIcon class="h-6 w-6 text-white/90 stroke-1" aria-hidden="true" />
				</div>
			</div>
			<p class="text-sm">
				In order to send this <span class="text-jmi-purple1 font-medium">{{ coo.type }}</span> <span class="text-jmi-purple1 font-medium">move {{ coo.direction }}</span> notification for <span class="text-jmi-purple1 font-medium">{{ coo.address }}</span> we need the following information:
			</p>
		</div>

		<div v-for="(error, key) in coo.errors" :key="key">
			<template v-if="error === 'supplierid'">
				<FormsSelectSupplier :key="error" v-model="supplierid" required="Please select a supplier" :type="coo.type" name="supplierid" />

				<div v-if="selectedSupplier?.does_not_accept_coos" class="bg-jmi-purple4  text-purple-1 p-3 rounded mt-2 text-sm">
					<SupplierDoesNotAcceptCoos :supplier="selectedSupplier" />
				</div>
			</template>
			<template v-else-if="error === 'movedate'">
				<div :key="error" class="flex">
					<FormsInput
						v-model="movedate"
						:required="false"
						:label="moveDateFields.movedate"
						:min="minMoveDate"
						:max="maxMoveDate"
						name="movedate"
						type="date"
						class="mt-4 flex-grow mr-4"
					/>
					<FormsInput
						v-model="movedate_confirmed"
						:required="false"
						:label="moveDateFields.movedate_confirmed_short"
						name="movedate_confirmed"
						type="switch"
						input-class="h-full justify-center"
						class="mt-4 flex flex-col"
					/>
				</div>
			</template>
			<template v-else-if="error === 'meter_details'">
				<FormsInput
					:key="error"
					v-model="at_property"
					:label="`Is there ${coo.type.toLowerCase()} at the property?`"
					name="at_property"
					type="switch"
				/>
			</template>
		</div>

		<template #links>
			<NuxtLink v-if="$route.name != 'moves-view-id'" class="text-jmi-purple3 text-sm underline" dusk="view-move" :to="'/moves/view/' + coo.moveid">
				View move
			</NuxtLink>
		</template>
	</ModalsForm>
</template>

<script setup lang="ts">
import {DialogTitle} from '@headlessui/vue'
import {CheckIcon, ExclamationTriangleIcon, EnvelopeIcon, BellSnoozeIcon, NoSymbolIcon} from '@heroicons/vue/24/solid';
import {computed, PropType, ref, watch} from "vue";
import {useCoos} from "~/stores/coos";
import type {CooRecord, SupplierRecord, SupplierType} from "~/utils/types";
import {maxMoveDate, minMoveDate} from "~/utils/getMinMaxAllowedMoveDates";
import getMoveDateFields from "~/utils/getMoveDateFields";
import {format} from "date-fns";
import {SupplierFacets, useSuppliers} from "~/stores/suppliers";
import SupplierDoesNotAcceptCoos from "~/components/move-notifications/SupplierDoesNotAcceptCoos.vue";

const props = defineProps({
	coo: {
		type: Object as PropType<CooRecord<SupplierType>>,
		required: false,
		default: null
	}
});

const emit = defineEmits(['close']);

const store = useCoos();
const suppliers = useSuppliers();

const moveDateFields = computed(() => {
	return getMoveDateFields(props.coo?.movetype, props.coo?.direction);
});
const supplierid = ref<number | null>(null);
const movedate = ref<string | null>(null);
const movedate_confirmed = ref(false);
const at_property = ref(false);
const is_open = ref(false);
const meters = ref([
	{
		serial_number: ""
	}
]);

const selectedSupplier = computed<SupplierRecord | undefined>(() => {
	const supplierId = supplierid.value;
	const cooType = props.coo?.type;
	return suppliers.instances['search-existing-' + cooType]?.queryData.find((record: SupplierRecord) => {
		return record.id === supplierId;
	});
});

watch(() => props.coo, () => {
	if (props.coo) {
		is_open.value = true;
		supplierid.value = null;
		movedate.value = props.coo.movedate ? format(new Date(props.coo.movedate), "yyyy-MM-dd") : null;
		movedate_confirmed.value = false;
		at_property.value = false;
		meters.value = [
			{
				serial_number: ""
			}
		];
	} else {
		is_open.value = false;
	}
});

const isSubmitting = ref(false);

interface CooFormData {
	supplierid: number | null,
	movedate: string | null,
	movedate_confirmed: boolean,
	at_property: boolean,
}

const onSubmit = async () => {
	isSubmitting.value = true;
	try {
		if (props.coo) {
			let formData: Partial<CooFormData> = {};

			for (const error of props.coo.errors) {
				if (error === "supplierid") {
					formData.supplierid = supplierid.value;
				} else if (error === "movedate") {
					formData.movedate = movedate.value;
					formData.movedate_confirmed = movedate_confirmed.value;
				} else if (error === "meter_details") {
					formData.at_property = at_property.value;
				}
			}

			await store.edit(props.coo.id, formData);
		}
		await store.refresh();
		emit('close');
	} finally {
		isSubmitting.value = false;
	}
};

const status = computed(() => {
	return props.coo ? props.coo.status : null;
});
</script>

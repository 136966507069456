<template>
	<ModalsDefault dusk="modals-form" :open="open" @close="$emit('close')">
		<form @submit="onSubmit">
			<button ref="submitButton" type="submit" class="hidden" />
			<div class="bg-jmi-purple5 p-4 rounded-t-lg flex items-center">
				<DialogTitle as="h3" class="text-jmi-purple1 flex-grow">
					<span class="block text-xl font-medium">{{ title }}</span>
					<small v-if="subtitle" class="block text-sm font-normal text-jmi-purple3">
						{{ subtitle }}
					</small>
				</DialogTitle>

				<button type="button" class="text-jmi-purple3 focus:outline-none focus:ring-0" dusk="close-modal" @click="$emit('close')">
					<span class="sr-only">Close</span>
					<XMarkIcon class="h-6 w-6 stroke-1" aria-hidden="true" />
				</button>
			</div>
			<div class="bg-white p-4">
				<slot />
			</div>
			<div class="bg-jmi-purple5 rounded-b-lg space-x-3 py-3 px-4 p-2 flex">
				<div class="w-1/2 flex items-center pl-2">
					<slot name="links" />
				</div>
				<div class="w-1/2 flex justify-end gap-2">
					<FormsButton ref="cancelButtonRef" color="greenOutline" dusk="modal-cancel-button" @click="$emit('close')">
						{{ labelCancel }}
					</FormsButton>
					<FormsButton color="green" :disabled="isSubmitting" dusk="modal-confirm-button" @click="triggerFormSubmit">
						{{ isSubmitting ? labelSubmitting : labelSubmit }}
					</FormsButton>
				</div>
			</div>
		</form>
	</ModalsDefault>
</template>

<script setup lang="ts">
import {XMarkIcon} from '@heroicons/vue/24/outline';
import {DialogTitle} from '@headlessui/vue';
import {ref} from "vue";
import {useForm} from "vee-validate";

const {handleSubmit} = useForm();
const emit = defineEmits(['submit', 'close']);

defineProps({
	open: {
		type: Boolean,
		required: true
	},
	title: {
		type: String,
		required: true
	},
	subtitle: {
		type: String,
		required: false,
		default: null
	},
	labelSubmit: {
		type: String,
		required: false,
		default: "Submit"
	},
	labelSubmitting: {
		type: String,
		required: false,
		default: "Submitting..."
	},
	labelCancel: {
		type: String,
		required: false,
		default: "Cancel"
	},
	isSubmitting: {
		type: Boolean,
		required: false,
		default: false
	}
});

const submitButton = ref<HTMLButtonElement | null>(null);

const triggerFormSubmit = () => {
	submitButton.value?.click();
};

const onSubmit = handleSubmit(() => {
	emit('submit');
});

</script>

import {isFetchError} from "~/utils/types";

export default function getErrorDetails(e: unknown) {
	let message = "An unknown error occurred.";
	let throwError = false;

	if (isFetchError(e)) {
		if (e.response?.status === 422) {
			const errorValues = Object.values(e.data.errors) as string[][];

			for (const errorValue of errorValues) {
				for (const error of Object.values(errorValue)) {
					message += '<p>' + error + '</p>';
				}
			}
		} else {
			throwError = true;
		}
	} else {
		throwError = true;
	}

	return {message, throwError};
}
